@import 'mixins.scss';
@import 'fonts.scss';
:root {
    --plyr-color-main: #9c7cd3;
}
* {
    // user-select: none;
    box-sizing: border-box;
}
body {
    position: relative;
    display: block;
    padding: 0px;
    margin: 0px;
    min-width: 320px;
    font-family: 'DB-Adman-X', 'tahoma';
    font-weight: 400;
    color: #000;
    font-size: 18px;
    background: #fafafa;
    @media(max-width: 1024px){
        font-size: 18px;
    }
    @media(max-width: 767px){
        font-size: 14px;
    }
}
a, a:hover, a:active, a:focus{
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    // &:hover{
    //     text-decoration: underline;
    // }
}

img{
    display: block;
    max-width: 100%;
    max-height: 100%;
}
ul, li {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    list-style-type: none;
}
.disable,
.disabled {
    filter: grayscale(100%) !important;
    cursor: default;
    pointer-events: none;
}
.default{
    cursor: default;
    pointer-events: none;
}

.recipe{
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgb(63, 60, 60);
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-image: linear-gradient(to right,
            #e4afcb 0%, #b8cbb8 0%, #b8cbb8 0%,
            #e2c58b 30%, #c2ce9c 64%, #7edbdc 100%);
    align-items: center;
    min-width: 40%;
    font-family: 'Lobster', cursive;
}

.image{
    border-radius: 10px;
    margin:0px 0px 20px 0px;
}